import React from 'react';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import './header.css';
import imgg from "../../assets/app2.gif"

const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h1>MetXR Studios</h1>
       
        <CTA />
        {/* <img src={imgg} alt="main" className='imgg10'/> */}
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
