import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './testimonials.css';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      link: 'https://www.linkedin.com/company/metxrstudios',
      name: 'THERMICRAFT INDUSTRIES',
      role: 'Cyber Security Engineer . Improving open-source projects, one commit at a time.',
      test: 'MetXR Studios delivers top-notch AR/VR solutions with unmatched creativity and expertise. Their team consistently provides high-quality, immersive experiences tailored to every need. Highly recommended for anyone seeking innovative AR/VR services!',
    },
    {
      id: 2,
      link: 'https://www.linkedin.com/company/metxrstudios',
      name: 'AKHILESH M V',
      role: 'Full-stack developer | Technical Support Engineer at Microverse',
      test: "Streamline your business processes with our ERP and billing software solutions. We offer tailor-made systems that integrate all aspects of your business, enhancing efficiency and improving data management.",
    },
    {
      id: 3,
      link: 'https://www.linkedin.com/company/metxrstudios',
      name: 'SREEPAD',
      role: 'Full Stack Developer| PostgreSQL | JavaScript | React | Redux | Html&Css | Python.',
      test: 'MetXR Studios delivers top-notch AR/VR solutions with unmatched creativity and expertise. Their team consistently provides high-quality, immersive experiences tailored to every need. Highly recommended for anyone seeking innovative AR/VR services!',
    },
    {
      id: 4,
      link: 'https://www.linkedin.com/company/metxrstudios',
      name: 'ADHARSH P V',
      role: 'Full-Stack Web Developer. JavaScript | Rails | React | Redux.',
      test: "MetXR Studios delivers top-notch AR/VR solutions with unmatched creativity and expertise. Their team consistently provides high-quality, immersive experiences tailored to every need. Highly recommended for anyone seeking innovative AR/VR services!",
    },
    {
      id: 5,
      link: 'https://www.linkedin.com/company/metxrstudios',
      name: 'ARJUN MK',
      role: 'Software Developer',
      test: "Professional and efficient office with a skilled team. Great environment and service!",
    },
  ];

  return (
    <section id="testimonials">
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Pagination, Autoplay]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 1500, // Delay between slides in milliseconds
          disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        loop={true} // Enable looping
      >
        {testimonials.map((test) => (
          <SwiperSlide className="testimonial" key={test.id}>
            <div className="client__avatar">
              <a href={test.link}>
                <BsLinkedin />
              </a>
            </div>
            <h5 className="client__name">{test.name}</h5>
            <small className="client__review">{test.test}</small>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
