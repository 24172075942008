import React, { useState } from 'react';
import './res.css';  // Import your custom CSS
import img23 from "../../assets/metxrloogo.svg"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle sidebar menu visibility
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar1">
      <div className="container1">
        
        {/* Left-aligned Logo */}
        <div className="logo-container">
          <a href="#home" className="logo">
           <img src={img23 } alt='logo'style={{width:100}}/>
          </a>
        </div>

        {/* Right-aligned Navigation Menu */}
        <div className="desktop-menu">
          <a href="#home" className="menu-item">Home</a>
          <a href="#about" className="menu-item">About Us</a>
          <a href="#experience" className="menu-item">Design</a>
          <a href="#portfolio" className="menu-item">Services</a>
          <a href="#testimonials" className="menu-item">Testimonials</a>
          <a href="#contact" className="menu-item">Contact Us</a>
        </div>

        {/* Mobile Menu Toggle Button (Only visible on mobile) */}
        <button 
          onClick={toggleMenu} 
          className="mobile-menu-button"
        >
          {/* <img 
            src="/path-to-your-menu-icon.png"  
            alt="Menu Icon"
            className="menu-icon"
          /> */}
        </button>
      </div>

      {/* Mobile Sidebar Menu */}
      {isOpen && (
        <div className="mobile-menu">
          <button 
            onClick={toggleMenu} 
            className="close-button"
          >
            &times;
          </button>
          <a href="#home" className="menu-item" onClick={toggleMenu}>Home</a>
          <a href="#about" className="menu-item" onClick={toggleMenu}>About Us</a>
          <a href="#experience" className="menu-item" onClick={toggleMenu}>Design</a>
          <a href="#portfolio" className="menu-item" onClick={toggleMenu}>Services</a>
          <a href="#testimonials" className="menu-item" onClick={toggleMenu}>Testimonials</a>
          <a href="#contact" className="menu-item" onClick={toggleMenu}>Contact Us</a>
        </div>
      )}

      {/* Overlay when sidebar is open */}
      {isOpen && (
        <div 
          onClick={toggleMenu} 
          className="overlay"
        />
      )}
    </div>
  );
};

export default Navbar;
