import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import './footer/footer.css';

const FooterA = () => {
  return (
    <footer style={{marginTop:10,height:50,textAlign:"center"}}>
      {/* <a href="#home" className="footer__logo">MetXR Studios</a>
      <ul className="permalinks">
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Skills</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul> */}
     
      <div className="footer__copyright" >
        <small>&copy; MetXR Studios 2024. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default FooterA