import "./intro.css";
import { FaAward } from "react-icons/fa";
import React from "react";
import { VscFolderLibrary } from "react-icons/vsc";
import img from '../../assets/intro.gif';
import { FaCalendarAlt, FaClock, FaFlag } from 'react-icons/fa';

const Intro = () => {
  return (
    <section id="about">
      <h2>About Us</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={img} alt="Rasif Taghizade" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaFlag className="about__icon" />
              <h5>Since</h5>
              <small>2023</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+ Completed Projects</small>
            </article>
          </div>
          <p>
            At MetXR Studios, we equip enterprises with cutting-edge AR and VR solutions to become Metaverse-ready. Our skilled team specializes in impactful product demos, dynamic web applications, and user-friendly mobile apps. With extensive experience in immersive content delivery, we partner closely with clients to bring their digital visions to life and ensure timely project completion.
          </p>
          <a href="#contact" className="btn btn-primary ">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
