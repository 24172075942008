import "./portfolio.css";
import IMG1 from "../../assets/VR5.gif";
import IMG2 from "../../assets/app2.gif";
import IMG3 from "../../assets/ERP2.gif";
import IMG4 from "../../assets/VR4.gif";
import IMG5 from "../../assets/IMG5.gif";
import IMG6 from "../../assets/IMG6.gif";
import React from "react";

// Portfolio function
const Portfolio = () => {
  const soloProjects = [
    {
      id: 1,
      title: "AR/VR",
      img: IMG1,
      description:
        "Immerse your users in fully interactive virtual worlds or enhance real-world environments with augmented reality. We specialize in creating cutting-edge AR and VR solutions for industries like healthcare, manufacturing, and education.",
      link: "https://educational-website-reactjs.netlify.app/",
      github: "https://github.com/Rasif-Taghizada/Educational-Website-ReactJs",
    },
    {
      id: 2,
      title: "WEB APP & MOBILE APP",
      img: IMG2,
      description:
        "Our team designs and develops custom websites and mobile apps that are visually appealing, responsive, and optimized for performance. Whether for e-commerce or corporate use, we ensure smooth functionality across all platforms.",
      link: "https://fh-huquq-com.vercel.app/",
      github: "https://github.com/Rasif-Taghizada/fh-huquq.com",
    },
    {
      id: 3,
      title: "ERP & CRM",
      img: IMG3,
      description:
        "Streamline your business processes with our ERP and billing software solutions. We offer tailor-made systems that integrate all aspects of your business, enhancing efficiency and improving data management.",
      link: "https://fs-code-task-two.vercel.app/",
      github: "https://github.com/Rasif-Taghizada/FS-Code-Task",
    },
    {
      id: 4,
      title: "3D MODELLING & ANIMATION",
      img: IMG4,
      description:
        "Bring your concepts to life with high-quality 3D modeling and animation. Whether it’s for AR, VR, or Metaverse environments, we deliver precise, lifelike models to meet your project’s needs.",
      link: "https://alpha-agency-project.vercel.app/",
      github: "https://github.com/Rasif-Taghizada/Alpha-Agency-Project",
    },
    {
      id: 5,
      title: "PRODUCT DEMOS",
      img: IMG5,
      description:
        "Showcase your products like never before with dynamic 3D animations and interactive demos. Designed for business pitches, sales presentations, and online displays, our product demos bring your products to life, making complex features easy to understand and visually engaging. Accessible across web, mobile, AR, and VR, they elevate the customer experience.",
      link: "https://jokes-project.vercel.app/",
      github: "https://github.com/Rasif-Taghizada/Joke-App",
    },
    {
      id: 6,
      title: "LEARNING MODULES",
      img: IMG6,
      description:
        "Engage and educate with our custom learning modules that combine immersive 3D visuals with interactive elements. Perfect for training sessions and e-learning, our modules simplify complex topics and ensure learners retain information effectively. Compatible with various platforms, including web, mobile, and VR, these modules make learning impactful and accessible.",
      link: "https://fs-poster-project.vercel.app/",
      github: "https://github.com/Rasif-Taghizada/Fs-Poster-Project",
    },
  ];

  return (
    <section id="portfolio">
      <h2>Services</h2>
      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
